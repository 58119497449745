<template>
  <crew />
</template>
<script>
import Crew from '@/components/Crew/Crew';
export default {
  components: { Crew },
  metaInfo() {
    return {
      title:
        'Karpicko 2024 - Obóz Integracyjno-Szkoleniowy Politechniki Poznańskiej',
    };
  },
};
</script>
